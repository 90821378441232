export const notificationListData = [
    {
        id: 'b06ca3f5-8fb0-4979-a016-30dfe63e8fd6',
        target: 'Jean Bowman',
        description: 'invited you to new project.',
        date: '4 minutes ago',
        image: 'thumb-8.jpg',
        type: 0,
        location: '',
        locationLabel: '',
        status: '',
        readed: false,
    },
    {
        id: '2152cd09-413a-44be-9d5a-b2b820c6a661',
        target: 'Vickie Kim',
        description: 'comment in your ticket.',
        date: '20 minutes ago',
        image: '',
        type: 0,
        location: '',
        locationLabel: '',
        status: '',
        readed: false,
    },
    {
        id: 'f644235d-dffc-4f17-883f-1ada117ff2c9',
        target: '',
        description: 'Please submit your daily report.',
        date: '1 hour ago',
        image: '',
        type: 1,
        location: '',
        locationLabel: '',
        status: '',
        readed: false,
    },
    {
        id: '8ca04d2c-0262-417b-8a3d-4ade49939059',
        target: '',
        description: 'Your request was rejected',
        date: '2 days ago',
        image: '',
        type: 2,
        location: '',
        locationLabel: '',
        status: 'failed',
        readed: true,
    },
    {
        id: 'e55adc24-1803-4ffd-b653-09be273f8df5',
        target: 'Jennifer Ruiz',
        description: 'mentioned your in comment.',
        date: '2 days ago',
        image: 'thumb-4.jpg',
        type: 0,
        location: '',
        locationLabel: '',
        status: '',
        readed: true,
    },
    {
        id: '8dd23dfd-a79b-40ad-b4e9-7e70a148d5b6',
        target: '',
        description: 'Your request has been approved.',
        date: '4 minutes ago',
        image: '4 days ago',
        type: 2,
        location: '',
        locationLabel: '',
        status: 'succeed',
        readed: true,
    },
]

export const searchQueryPoolData = [
    {
        title: 'Customer Details',
        url: '/app/crm/customer-details?id=8',
        icon: 'crm',
        category: 'CRM',
        categoryTitle: 'Apps',
    },
    {
        title: 'Mail',
        url: '/app/crm/mail',
        icon: 'crm',
        category: 'CRM',
        categoryTitle: 'Apps',
    },
    {
        title: 'Sales Dashboard',
        url: '/app/sales/dashboard',
        icon: 'sales',
        category: 'Sales',
        categoryTitle: 'Apps',
    },
    {
        title: 'Product List',
        url: '/app/sales/product-list',
        icon: 'sales',
        category: 'Sales',
        categoryTitle: 'Apps',
    },
    {
        title: 'Product Edit',
        url: '/app/sales/product-edit/12',
        icon: 'sales',
        category: 'Sales',
        categoryTitle: 'Apps',
    },
    {
        title: 'New Product',
        url: '/app/sales/product-new',
        icon: 'sales',
        category: 'Sales',
        categoryTitle: 'Apps',
    },
    {
        title: 'KYC Form',
        url: '/app/account/kyc-form',
        icon: 'account',
        category: 'Account',
        categoryTitle: 'Apps',
    },
    {
        title: 'Button',
        url: '/ui-components/button',
        icon: 'common',
        category: 'Common',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Tooltip',
        url: '/ui-components/tooltip',
        icon: 'dataDisplay',
        category: 'Data Display',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Checkbox',
        url: '/ui-components/checkbox',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Date picker',
        url: '/ui-components/date-picker',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Form control',
        url: '/ui-components/form-control',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Input',
        url: '/ui-components/input',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Input Group',
        url: '/ui-components/input-group',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Radio',
        url: '/ui-components/radio',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Segment',
        url: '/ui-components/segment',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Select',
        url: '/ui-components/select',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Switcher',
        url: '/ui-components/switcher',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'TimeInput',
        url: '/ui-components/time-input',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Upload',
        url: '/ui-components/upload',
        icon: 'forms',
        category: 'Forms',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Dropdown',
        url: '/ui-components/dropdown',
        icon: 'navigation',
        category: 'Navigation',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Menu',
        url: '/ui-components/menu',
        icon: 'navigation',
        category: 'Navigation',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Pagination',
        url: '/ui-components/pagination',
        icon: 'navigation',
        category: 'Navigation',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Steps',
        url: '/ui-components/steps',
        icon: 'navigation',
        category: 'Navigation',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Tabs',
        url: '/ui-components/tabs',
        icon: 'navigation',
        category: 'Navigation',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Charts',
        url: '/ui-components/charts',
        icon: 'graph',
        category: 'Graph',
        categoryTitle: 'UI Components',
    },
    {
        title: 'Maps',
        url: '/ui-components/maps',
        icon: 'graph',
        category: 'Graph',
        categoryTitle: 'UI Components',
    },
]
