const appConfig = {
    apiPrefix: '/api',
    // backendUrl: 'http://0.0.0.0:8000',
    backendUrl: 'https://machinetomachine.ai',//'http://machinetomachine.ai',//http://0.0.00.0:8000
    authenticatedEntryPath: '/app/dashboard',
    unAuthenticatedEntryPath: '/home',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: true,
}

export default appConfig
