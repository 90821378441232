export const helpCenterCategoriesData = [
    {
        id: '0',
        name: 'Survey',
        articleCounts: 10,
    },
    {
        id: '1',
        name: 'Themes',
        articleCounts: 6,
    },
    {
        id: '2',
        name: 'Security',
        articleCounts: 17,
    },
    {
        id: '3',
        name: 'Integration',
        articleCounts: 9,
    },
    {
        id: '4',
        name: 'Media',
        articleCounts: 12,
    },
    {
        id: '5',
        name: 'Analytic',
        articleCounts: 18,
    },
    {
        id: '6',
        name: 'Chatbot',
        articleCounts: 3,
    },
    {
        id: '7',
        name: 'Commission',
        articleCounts: 8,
    },
]

export const helpCenterArticleListData = [
    {
        id: 'pWBKE_0UiQ',
        title: '',
        content:
            '',
        category: 'themes',
        authors: [
            {
                name: 'Ron Vargas',
                img: '/img/avatars/thumb-3.jpg',
            },
        ],
        starred: true,
        updateTime: '2 weeks ago',
        createdBy: 'Ron Vargas',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: '2tv0CboXfj',
        title: 'Understand the background of themes',
        content:
            '',
        category: 'themes',
        authors: [
            {
                name: 'Carolyn Hanson',
                img: '/img/avatars/thumb-9.jpg',
            },
            {
                name: 'Samantha Phillips',
                img: '/img/avatars/thumb-6.jpg',
            },
        ],
        starred: true,
        updateTime: '6 months ago',
        createdBy: 'Carolyn Hanson',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'XHpEwVPi4D',
        title: 'Basic tools you need to know on configuring the theme',
        content:
            '',
        category: 'themes',
        authors: [
            {
                name: 'Lloyd Obrien',
                img: '/img/avatars/thumb-11.jpg',
            },
        ],
        starred: false,
        updateTime: '4 months ago',
        createdBy: 'Lloyd Obrien',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: '09R6gIH5zD',
        title: 'Simple guidance for you in theming',
        content:
            '',
        category: 'themes',
        authors: [
            {
                name: 'Joyce Freeman',
                img: '/img/avatars/thumb-5.jpg',
            },
        ],
        starred: false,
        updateTime: '3 months ago',
        createdBy: 'Joyce Freeman',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'rZjCbSyae5',
        title: 'How to create a survey form',
        content:
            '',
        category: 'survey',
        authors: [
            {
                name: 'Terrance Moreno',
                img: '/img/avatars/thumb-2.jpg',
            },
        ],
        starred: true,
        updateTime: '3 months ago',
        createdBy: 'Terrance Moreno',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'mmPo6vqS1t',
        title: 'Survey form best practice',
        content:
            '',
        category: 'survey',
        authors: [
            {
                name: 'Luke Cook',
                img: '/img/avatars/thumb-4.jpg',
            },
            {
                name: 'Samantha Phillips',
                img: '/img/avatars/thumb-6.jpg',
            },
            {
                name: 'Joyce Freeman',
                img: '/img/avatars/thumb-5.jpg',
            },
        ],
        starred: false,
        updateTime: 'Last week',
        createdBy: 'Luke Cook',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: '6ETEWm8ZHt',
        title: '',
        content:
            '',
        category: 'survey',
        authors: [
            {
                name: 'Joyce Freeman',
                img: '/img/avatars/thumb-5.jpg',
            },
        ],
        starred: false,
        updateTime: '2 months ago',
        createdBy: 'Joyce Freeman',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'WQCy5CZcEJ',
        title: 'Quick tips regarding security',
        content:
            '',
        category: 'security',
        authors: [
            {
                name: 'Tara Fletcher',
                img: '/img/avatars/thumb-7.jpg',
            },
        ],
        starred: false,
        updateTime: '4 months ago',
        createdBy: 'Tara Fletcher',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'prUH65MCWD',
        title: 'All You need to know about privacy setting',
        content:
            '',
        category: 'security',
        authors: [
            {
                name: 'Tara Fletcher',
                img: '/img/avatars/thumb-7.jpg',
            },
        ],
        starred: false,
        updateTime: '8 months ago',
        createdBy: 'Tara Fletcher',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: '1xK5PJRunm',
        title: 'Simple guide for integration',
        content:
            '',
        category: 'integration',
        authors: [
            {
                name: 'Frederick Adams',
                img: '/img/avatars/thumb-8.jpg',
            },
        ],
        starred: false,
        updateTime: '7 months ago',
        createdBy: 'Frederick Adams',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'iVB_L9xy2d',
        title: 'Most effective ways to overcome integration issue.',
        content:
            '',
        category: 'integration',
        authors: [
            {
                name: 'Gabriella May',
                img: '/img/avatars/thumb-12.jpg',
            },
            {
                name: 'Gail Barnes',
                img: '/img/avatars/thumb-14.jpg',
            },
        ],
        starred: true,
        updateTime: '3 days ago',
        createdBy: 'Gabriella May',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 't6D3gCV8hN',
        title: 'API document for integration',
        content:
            '',
        category: 'integration',
        authors: [
            {
                name: 'Ella Robinson',
                img: '/img/avatars/thumb-15.jpg',
            },
        ],
        starred: false,
        updateTime: '2 weeks ago',
        createdBy: 'Gabriella May',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'gI3fhHZzoQ',
        title: '',
        content:
            '',
        category: 'integration',
        authors: [
            {
                name: 'Lee Wheeler',
                img: '/img/avatars/thumb-13.jpg',
            },
        ],
        starred: false,
        updateTime: '3 weeks ago',
        createdBy: 'Lee Wheeler',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: '4zu-IVncIh',
        title: '',
        content:
            '',
        category: 'media',
        authors: [
            {
                name: 'John Doe',
                img: '/img/avatars/thumb-10.jpg',
            },
        ],
        starred: true,
        updateTime: 'a year ago',
        createdBy: 'John Doe',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: '4zu-WQX60M9MDJ',
        title: 'Beware of oversized',
        content:
            '',
        category: 'media',
        authors: [
            {
                name: 'Terrance Moreno',
                img: '/img/avatars/thumb-2.jpg',
            },
            {
                name: 'John Doe',
                img: '/img/avatars/thumb-10.jpg',
            },
        ],
        starred: false,
        updateTime: 'a year ago',
        createdBy: 'Terrance Moreno',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'yHE5vZ4sow',
        title: '',
        content:
            '',
        category: 'media',
        authors: [
            {
                name: 'Ron Vargas',
                img: '/img/avatars/thumb-3.jpg',
            },
        ],
        starred: false,
        updateTime: '9 months ago',
        createdBy: 'Ron Vargas',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'CZZxidbzLi',
        title: '',
        content:
            '',
        category: 'analytic',
        authors: [
            {
                name: 'Luke Cook',
                img: '/img/avatars/thumb-4.jpg',
            },
        ],
        starred: true,
        updateTime: '3 months ago',
        createdBy: 'Luke Cook',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'aOuPn7DxZz',
        title: '',
        content:
            '',
        category: 'analytic',
        authors: [
            {
                name: 'Carolyn Hanson',
                img: '/img/avatars/thumb-9.jpg',
            },
        ],
        starred: false,
        updateTime: '4 months ago',
        createdBy: 'Carolyn Hanson',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: '90hXaTVMr3',
        title: '',
        content:
            '',
        category: 'analytic',
        authors: [
            {
                name: 'Carolyn Perkins',
                img: '/img/avatars/thumb-1.jpg',
            },
        ],
        starred: false,
        updateTime: '10 months ago',
        createdBy: 'Eileen Horton',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'bd3Hxes4NB',
        title: '',
        content:
            '',
        category: 'chatbot',
        authors: [
            {
                name: 'Terrance Moreno',
                img: '/img/avatars/thumb-2.jpg',
            },
        ],
        starred: false,
        updateTime: '2 months ago',
        createdBy: 'Terrance Moreno',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'zcZn3PBqKf',
        title: '',
        content:
            '',
        category: 'chatbot',
        authors: [
            {
                name: 'Frederick Adams',
                img: '/img/avatars/thumb-8.jpg',
            },
        ],
        starred: false,
        updateTime: '5 months ago',
        createdBy: 'Frederick Adams',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'LWy8fEP-tA',
        title: '',
        content:
            '',
        category: 'commission',
        authors: [
            {
                name: 'Lee Wheeler',
                img: '/img/avatars/thumb-13.jpg',
            },
        ],
        starred: false,
        updateTime: '5 days ago',
        createdBy: 'Lee Wheeler',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'SAxrgvMuip',
        title: '',
        content:
            '',
        category: 'commission',
        authors: [
            {
                name: 'Joyce Freeman',
                img: '/img/avatars/thumb-5.jpg',
            },
        ],
        starred: false,
        updateTime: 'a year ago',
        createdBy: 'Joyce Freeman',
        timeToRead: 2,
        viewCount: 37,
    },
    {
        id: 'NcUFsNDmUV',
        title: '',
        content:
            '',
        category: 'commission',
        authors: [
            {
                name: 'Carolyn Perkins',
                img: '/img/avatars/thumb-1.jpg',
            },
            {
                name: 'Gabriella May',
                img: '/img/avatars/thumb-12.jpg',
            },
        ],
        starred: true,
        updateTime: 'a year ago',
        createdBy: 'Eileen Horton',
        timeToRead: 2,
        viewCount: 37,
    },
]
